<template>
  <div class="page" v-if="productInfo != null">
    <div class="productDetail">
      <div class="body">
        <div class="image" v-if="arr.length == 1">
          <img :src="productInfo.goods_image" :alt="productInfo.goods_name" />
        </div>
        <div class="swiper" v-else>
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in arr" :key="index">
              <!-- :style="{
            backgroundImage: 'url(' + require('../assets/img/banner.png') + ')',
            backgroundSzie: '100% 100%',
          }" -->
              <img :src="item" alt="" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="title">{{ productInfo.goods_name }}</div>
        <!-- 接送机-->
        <!-- 下午茶 酒店-->
        <div
          v-if="productInfo.speciesdata == 4 || productInfo.speciesdata == 5"
        >
          <div class="line line1"></div>
          <div class="note note-mt">请访问此网址查看支持门店:</div>
          <a
            v-if="productInfo.shopdata == 1 || productInfo.shopdata == '1'"
            class="note"
            href="https://h5-gate.bestdo.com/showCardProductList?c=3546"
            >https://h5-gate.bestdo.com/showCardProductList?c=3546</a
          >
          <a
            class="note"
            v-if="productInfo.shopdata == 2 || productInfo.shopdata == '2'"
            href="https://h5-gate.bestdo.com/showCardProductList?c=3545"
            >https://h5-gate.bestdo.com/showCardProductList?c=3545</a
          >
          <a
            class="note"
            v-if="productInfo.shopdata == 3 || productInfo.shopdata == '3'"
            href="https://h5-gate.bestdo.com/showCardProductList?c=3545"
            >https://h5-gate.bestdo.com/showCardProductList?c=3545</a
          >
        </div>
      </div>

      <div class="descTitle">兑换品介绍</div>
      <div class="descBody">{{ productInfo.goods_Introduction }}</div>
      <div class="line"></div>
      <div class="descBody" v-html="productInfo.goods_content" />
      <div class="line"></div>
      <div class="bottom">
        <BottomCom />
      </div>
    </div>
    <div class="footer">
      <span @click="goBack">返回</span>
      <span @click="preSubmit">领取</span>
    </div>
    <SubmitModal
      :modal="submitModal"
      :title="productInfo.name"
      v-on:closeModal="closeSubmitModal"
      v-on:submitModal="sureSubmitModal"
    />
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

import _ from "lodash";
import { getProductDetail, submitProduct } from "../api/data";
import { Toast } from "vant";
import BottomCom from "../components/BottomCom";
import SubmitModal from "../components/SubmitModal";

export default {
  name: "ProductDetail",
  components: { SubmitModal, BottomCom, swiper, swiperSlide },
  data: function() {
    return {
      submitModal: false,
      productInfo: null,
      arr: [],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true, // 循环
        autoplay: true,
      },
    };
  },
  async beforeMount() {
    await this.queryConvertDetailList();
  },
  methods: {
    async queryConvertDetailList() {
      let param = {
        id: _.toInteger(this.$route.query.id),
        goods_id: _.toInteger(this.$route.query.goodId),
      };
      let res = await getProductDetail(param);
      if (_.toInteger(res.code) === 1) {
        this.productInfo = res.data;
        if (res.data.goods_images.length > 0) {
          let arr = res.data.goods_images;
          //   arr.unshift(res.data.goods_image);
          console.log("arr", arr);
          this.arr = arr;
        }
      } else {
        Toast.fail(res.msg);
      }
    },
    closeSubmitModal() {
      this.submitModal = false;
    },
    async goBack() {
      await this.$router.back();
    },
    preSubmit() {
      this.submitModal = true;
    },
    async sureSubmitModal() {
      this.submitModal = false;
      let productInfo = this.productInfo;
      let goodsData = _.toInteger(productInfo.speciesdata);
      let param = {
        id: productInfo.id,
        goods_id: productInfo.goods_id,
      };
      // console.log("productInfo",productInfo)
      console.log("type=", productInfo.type); //0未兑换,1已兑换,2您已兑换其它权益
      if (_.toInteger(productInfo.type) === 1) {
        Toast.fail("您已兑换该权益");
        return false;
      } else if (_.toInteger(productInfo.type) === 2) {
        Toast.fail("您已兑换其它权益");
        return false;
      }
      console.log("goodsData=", goodsData); //1=实物,2=电子卡券,3=第三方票务,4=下午茶,5=酒店权益,6=景点门票,7=接送机
      if (goodsData === 1) {
        //实物
        localStorage.setItem("productItem", JSON.stringify(productInfo));
        await this.$router.push("MatterSubmit");
        return false;
      } else if (goodsData === 2) {
        //电子卡券
        let res = await submitProduct(param);
        if (_.toInteger(res.code) === 1) {
          await this.$router.push({
            name: "Orderdetail",
            query: { orderId: res.data.order_id },
          });
        } else {
          Toast.fail(res.msg);
        }
        return false;
      } else if (goodsData === 3) {
        //第三方票务
        let res = await submitProduct(param);
        if (_.toInteger(res.code) === 1) {
          await this.$router.push({
            name: "Orderdetail",
            query: { orderId: res.data.order_id },
          });
        } else {
          Toast.fail(res.msg);
        }
      } else if (goodsData === 4) {
        //下午茶
        let res = await submitProduct(param);
        if (_.toInteger(res.code) === 1) {
          await this.$router.push({
            name: "Orderdetail",
            query: { orderId: res.data.order_id },
          });
        } else {
          Toast.fail(res.msg);
        }
        return false;
      } else if (goodsData === 5) {
        //酒店权益
        let res = await submitProduct(param);
        if (_.toInteger(res.code) === 1) {
          await this.$router.push({
            name: "Orderdetail",
            query: { orderId: res.data.order_id },
          });
        } else {
          Toast.fail(res.msg);
        }
        return false;
      } else if (goodsData === 6) {
        //景点门票
        localStorage.setItem("productItem", JSON.stringify(productInfo));
        await this.$router.push("TicketsSubmit");
        return false;
      } else if (goodsData === 7) {
        //接送机
        localStorage.setItem("productItem", JSON.stringify(productInfo));
        await this.$router.push("AirportSubmit");
        return false;
      } else {
        Toast.fail("异常");
        return false;
      }
    },
  },
};
</script>

<style scoped>
.note-mt {
  padding-top: 57px;
}
.note {
  width: 562px;
  font-size: 24px;
  font-weight: 500;
  color: #b19485;
  margin: 10px 0;
}

.productDetail .line1 {
  position: absolute;
  margin: 0 !important;
  top: 750px;
  left: calc((100% - 600px) / 2);
  background: linear-gradient(
    90deg,
    rgba(239, 194, 142, 0) 0%,
    #f0c595 49%,
    rgba(239, 194, 141, 0) 100%
  );
}

.productDetail {
  display: flex;
  flex-direction: column;
  width: 690px;
  flex: 1;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 24px 148px 24px;
  box-sizing: border-box;
  overflow-y: auto;
}

.productDetail .body {
  width: 642px;
  border: 2px solid #d4cecb;
  background: linear-gradient(131deg, #3a3d43 0%, #23262b 100%);
  border-radius: 12px;
  padding: 40px;
  box-sizing: border-box;
}

.productDetail .body .image {
  width: 562px;
  height: auto;
  min-height: 582px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productDetail .body .image img {
  max-width: 562px;
  max-height: 562px;
}

.swiper {
  width: 562px;
  height: 562px;
}

.swiper img {
  width: 562px;
  height: 562px;
}

.productDetail .body .title {
  width: 562px;
  font-size: 36px;
  font-weight: 500;
  color: #e3c096;
  margin-top: 45px;
}

.productDetail .descTitle {
  width: 642px;
  margin-top: 40px;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  color: #272a2f;
}

.productDetail .descBody {
  width: 642px;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  color: #272a2f;
  overflow-x: hidden;
}

.productDetail .descBody img {
  max-width: 100%;
}

.productDetail .line {
  width: 600px;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(37, 40, 45, 0) 0%,
    #25282d 49%,
    rgba(35, 38, 43, 0) 100%
  );
  margin: 50px 0;
}

.productDetail .bottom {
  width: 642px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page .footer {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  width: 690px;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.page .footer span {
  flex: 1;
  margin: 0 20px;
  height: 80px;
  border-radius: 40px;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page .footer span:first-child {
  border: 2px solid #222529;
  color: #212429;
}

.page .footer span:last-child {
  border: 2px solid #707070;
  background: linear-gradient(125deg, #3a3d43 0%, #202327 100%);
  color: #e2be93;
}
</style>
