<template>
    <van-popup class="model" :close-on-click-overlay="false" @click-overlay="closeModal" v-model="modal">
        <div class="submitBody">
            <div class="title">是否确认选择此礼品?</div>
            <div class="desc">{{title}}限选择一份礼品,权益一经兑换不允许修改</div>
            <div class="group">
                <div class="cancel" @click="closeModal">再看看</div>
                <div class="sure" @click="submitModal">确认</div>
            </div>
        </div>
    </van-popup>
</template>

<script>
    export default {
        name: "SubmitModal",
        props: ['modal', 'title'],
        methods: {
            async submitModal() {
                await this.$emit('submitModal')
            },
            closeModal() {
                this.$emit('closeModal')
            }
        }
    }
</script>

<style scoped>
    .model {
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model .submitBody {
        width: 612px;
        background: #FFFFFF;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
    }

    .model .submitBody .title {
        width: 100%;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #171A1D;
        text-align: center;
        margin: 30px 0 60px 0;
    }

    .model .submitBody .desc {
        width: 100%;
        padding: 10px 50px;
        box-sizing: border-box;
        font-size: 30px;
        font-weight: 400;
        line-height: 48px;
        color: #646464;
        text-align: center;
        margin: 30px 0;
    }

    .model .submitBody .group {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 50px 0;
    }

    .model .submitBody .group .sure {
        flex: 1;
        height: 80px;
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
    }

    .model .submitBody .group .cancel {
        flex: 1;
        height: 80px;
        border: 2px solid #222529;
        border-radius: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #212429;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
    }
</style>
